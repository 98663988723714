<script setup lang="ts">
import { useInfoStore } from '~~/store/info';
import SettingBannerWideItem, {
  SettingBannerWideItemAttrs,
} from '~~/utils/models/SettingBannerWideItem';

const props = defineProps<{
  item: SettingBannerWideItemAttrs;
  isActive: boolean;
}>();
const infoStore = useInfoStore();
const banner$ = computed(() => new SettingBannerWideItem(props.item));
const el = ref<HTMLVideoElement>();

watch(
  () => props.isActive,
  (isActive) => {
    isActive ? el.value?.play() : el.value?.pause();
  }
);
</script>

<template>
  <video
    class="w-full h-full bg-black object-contain md:object-cover"
    loop
    muted
    playsinline
    disablePictureInPicture
    ref="el"
  >
    <ClientOnly>
      <source
        type="video/mp4"
        :src="
          infoStore.screen === 'xs'
            ? banner$.videoPortraitURL
            : banner$.videoURL
        "
      />
    </ClientOnly>
    <component is="noscript">
      <source type="video/mp4" :src="banner$.videoURL" />
    </component>
  </video>
</template>
